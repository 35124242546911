// LIBRARIES
import React from "react"
import moment from "moment";

// STYLE
import { MarketingCampaignListItem } from "./styled"
import {Container, Label} from "../../../../Assets/Structure/Structure";


export default function MarketingCampaign(props) {

    const createdAt = moment(props.marketingCampaign.createdAt).format("MM/DD/YYYY")

    return (
        <MarketingCampaignListItem>
            <Container flex alignCenter fluid maxWidth="20em">
                <Container flex column fluid maxWidth="16em">
                    <Label color="#999">Campaign name</Label>
                    <Container mt="10px">
                        <Label fontSize="16px">{props.marketingCampaign.name}</Label>
                    </Container>
                </Container>
            </Container>
            <Container className="marketing-campaign-list-item--right-column" flex alignCenter fluid justifyEnd wrap>
                <Container className="marketing-campaign-list-item--right-column__item" flex column fluid maxWidth="14em">
                    <Label color="#999">Location</Label>
                    <Container mt="10px">
                        <Label fontSize="16px">{props.marketingCampaign.locationName}</Label>
                    </Container>
                </Container>
                <Container className="marketing-campaign-list-item--right-column__item" flex column fluid maxWidth="12em">
                    <Label color="#999">Created at</Label>
                    <Container mt="10px">
                        <Label fontSize="16px">{createdAt}</Label>
                    </Container>
                </Container>
                <Container className="marketing-campaign-list-item--right-column__item" flex column fluid maxWidth="8em">
                    <Label color="#999">Sent</Label>
                    <Container mt="10px">
                        <Label fontSize="16px">{props.marketingCampaign.targetsAmount}</Label>
                    </Container>
                </Container>
                <Container className="marketing-campaign-list-item--right-column__item" flex column>
                    <Label color="#999">Replies</Label>
                    <Container mt="10px">
                        <Label fontSize="16px">{props.marketingCampaign.replies || 0}</Label>
                    </Container>
                </Container>
            </Container>
        </MarketingCampaignListItem>
    )
}
