import React, {useEffect, useState} from "react"
import { connect } from "react-redux"
import ReactSVG from "react-svg"
import Skeleton from "react-skeleton-loader"

// STYLE
import { SMSMarketingContainer } from "./styled"
import {Container, Label, PageWrapper} from "../../Assets/Structure/Structure"

// STORE ACTIONS
import { fetchMarketingCampaigns } from "../../store/modules/smsMarketing/actions"
import { fetchLocations } from "../../store/modules/locations/actions"
import {fetchChatCredentials, setShowChatsOnMobile} from "../../store/modules/webchat/actions"
import EmptyFeedback from "../../Components/EmptyFeedback/EmptyFeedback"

// ASSETS
import smsPhoneIcon from "../../Assets/icons/smsPhone.svg"
import mobileSMSIcon from "../../Assets/icons/mobile-sms.svg"
import CreateMarketingCampaignDialog
    from "../../Containers/MarketingCampaign/CreateMarketingCampaignDialog/CreateMarketingCampaignDialog";
import MarketingCampaignsList from "../../Containers/MarketingCampaign/MarketingCampaignsList/MarketingCampaignsList";

import { Button } from "@material-ui/core"
import MarketingCampaignListSkeleton
    from "../../Containers/MarketingCampaign/MarketingCampaignsList/MarketingCampaignListSkeleton";


function SMSMarketing(props) {
    //STATE
    const [showEmptyFeedback, setShowEmptyFeedback] = useState(false)
    const [state, setState] = useState({
        loading: true,
        showCreateMarketingCampaignDialog: false
    })

    // MOUNTED
    useEffect(() => {
        getWebchatCredentials()
    }, [])

    // WATCHERS
    useEffect(() => {
        if (props.marketingCampaigns.length === 0) {
            setState({ ...state, loading: false })
            setShowEmptyFeedback(true)
        } else {
            setState({ ...state, loading: false })
            setShowEmptyFeedback(false)
        }
    }, [props.marketingCampaigns])

    useEffect(() => {
        getMarketingCampaigns()
        getLocations()
    }, [props.chatCredentials])

    // METHODS
    const getWebchatCredentials = () => {
        props.fetchChatCredentials()
    }

    const getMarketingCampaigns = () => {
        setState({ ...state, loading: true })

        const storeId = localStorage.getItem("store")
        props.fetchMarketingCampaigns(storeId)
    }

    const getLocations = () => {
        const storeId = localStorage.getItem("store")
        props.fetchLocations(storeId)
    }

    const handleShowCreateMarketingCampaignDialog = async (showCreateMarketingCampaignDialog) => {
        setState({ ...state, showCreateMarketingCampaignDialog })
    }

    const handleSuccess = () => {
        setShowEmptyFeedback(false)
    }

    return (
        <>
            <SMSMarketingContainer>
                <PageWrapper>
                    {state.loading ? (
                        <Container mt="20px" padding="0 40px">
                            <Skeleton height="40px" width="200px" />
                            <Container mt="40px">
                                <MarketingCampaignListSkeleton />
                            </Container>
                        </Container>
                    ) : (
                        showEmptyFeedback ? (
                            <EmptyFeedback
                            textButton
                            iconPath={smsPhoneIcon}
                            svgWidth="50px"
                            buttonText="Create campaign"
                            svgHeight="50px"
                            text="No campaigns were created"
                            onClick={() => handleShowCreateMarketingCampaignDialog(true)}/>
                        ) : (
                            <Container>
                                <Container mt="20px" padding="0 40px">
                                    <Button
                                    className="create-marketing-campaign--button"
                                    style={{ backgroundColor: "white",  borderRadius: "20em" }}
                                    onClick={() => handleShowCreateMarketingCampaignDialog(true)}>
                                        <Container flex alignCenter>
                                            <ReactSVG className="create-marketing-campaign--button__icon" src={mobileSMSIcon} />
                                            <Container ml="10px">
                                                Create Campaign
                                            </Container>
                                        </Container>
                                    </Button>
                                </Container>
                                <MarketingCampaignsList {...props} />
                            </Container>
                        )
                    )}
                </PageWrapper>
            </SMSMarketingContainer>
            <CreateMarketingCampaignDialog
                active={state.showCreateMarketingCampaignDialog}
                onClose={() => handleShowCreateMarketingCampaignDialog(false)}
                onSuccess={handleSuccess}/>
        </>
    )
}

const mapStateToProps = state => ({
    marketingCampaigns: state.smsMarketing.marketingCampaigns
})

const mapDispatchToProps = dispatch => ({
    fetchMarketingCampaigns(storeId) {
        dispatch(fetchMarketingCampaigns(storeId))
    },

    fetchLocations(storeId) {
        dispatch(fetchLocations(storeId))
    },

    fetchChatCredentials() {
        dispatch(fetchChatCredentials())
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(SMSMarketing)
