// LIBRARIES
import React, {useEffect, useState} from "react"
import ReactSVG from "react-svg"
import axios from "axios"
import { connect } from "react-redux"
import { Dialog, Input } from "@material-ui/core"

// STYLES
import { CreateMarketingCampaignDialogContainer } from "./styled"
import {CloseButtonContainer} from "../../Webchat/ComposeMessageDialog/styled"

// CONFIG
import { WEBCHAT_API_URL } from "../../../config";

// STORE ACTIONS
import { updateChatGroupsMenu, setCurrentChat } from "../../../store/modules/webchat/actions"
import { createSMSMarketingCampaign } from "../../../store/modules/smsMarketing/actions"

// STORE MODELS
import SMSMarketingCampaign from "../../../store/modules/smsMarketing/model"

// BUSINESS COMPONENTS
import ImportContactsDialog from "../ImportContactsDialog/ImportContactsDialog"
import ClientsList from "../ClientsList/ClientsList"

// STRUCTURE COMPONENTS
import { Container, Label } from "../../../Assets/Structure/Structure"

// Components
import { Textarea } from "../../../Pages/Reviews/AnswerReview/styled"
import Button from "../../../Components/Button/Button"
import SelectFluid from "../../../Components/SelectFluid/SelectFluid"

// ASSETS
import closeIcon from "../../../Assets/icons/close.svg"
import downloadIcon from "../../../Assets/icons/download.svg"
import AddClient from "../AddClient/AddClient";
import store from "../../../store";


function CreateMarketingCampaignDialog(props) {
    // STATES
    const [campaignName, setCampaignName] = useState("")
    const [enableSubmitCampaign, setEnableSubmitCampaign] = useState(false)
    const [showImportCSVDialog, setShowImportCSVDialog] = useState(false)
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [selectedLocation, setSelectedLocation] = useState(null)
    const [selectedLocationPlaceholder, setSelectedLocationPlaceholder] = useState("Select location")
    const [clients, setClients] = useState([])

    // WATCHERS
    useEffect(() => {
        if (!props.active) resetLocalState()
    }, [props.active])

    useEffect(() => {
        if (props.locations.length === 2) {
            const autoSelectedLocation = props.locations[0]

            setSelectedLocationPlaceholder(autoSelectedLocation.name)
            setSelectedLocation(autoSelectedLocation)
        }
    }, [props.locations])

    // METHODS
    const resetLocalState = () => {
        setMessage("")
        setLoading(false)
        setEnableSubmitCampaign(false)
        setShowImportCSVDialog(false)
        setClients([])
        setSelectedLocation(null)
        setCampaignName("")
    }

    const handleDeleteClient = (clientToBeDeleted) => {
        setClients(clients.filter(client => client !== clientToBeDeleted))
    }

    const handleSendMessages = async () => {
        try {
            setLoading(true)
            await submitCampaign()
            await fetchChatGroupsMenu()
            setLoading(false)
            props.onClose()
        }
        catch(error) {
            setLoading(false)
            props.onClose()
        }
    }

    const fetchChatGroupsMenu = () => {
        const storeId = localStorage.getItem('store');

        const headers = {
            headers: {
                "x-auth-token": props.chatCredentials.twilioAuthToken ,
                "x-account-sid": props.chatCredentials.accountSid
            }
        }

        axios.get(`${WEBCHAT_API_URL}/stores/${storeId}/messages/menu`, headers)
            .then(res => {
                const { chatGroups } = res.data
                props.updateChatGroupsMenu(true, chatGroups)
            })
    }

    const handleCampaignName = (e) => {
        setCampaignName(e.target.value)
    }

    const handleMessage = (message) => {
        setMessage(message)
    }

    useEffect(() => {
        if (clients.length !== 0 && selectedLocation && campaignName) {
            setEnableSubmitCampaign(true)
        } else {
            setEnableSubmitCampaign(false)
        }
    }, [clients, message, selectedLocation])


    const submitCampaign = async () => {
            try {
                const storeId = localStorage.getItem("store")
                const locationId = selectedLocation.id

                const headers = {
                    headers: {
                        "x-auth-token": props.chatCredentials.twilioAuthToken,
                        "x-account-sid": props.chatCredentials.accountSid
                    }
                }

                const targets = clients.map(client => {
                    return { number: client.clientNumber, name: client.clientName }
                })

                const staffName = localStorage.getItem("user_name")
                const storeName = localStorage.getItem("store_name")

                const smsMarketingCampaign = new SMSMarketingCampaign({
                    name: campaignName,
                    locationName: selectedLocation.name,
                    message,
                    targets,
                    staffName,
                    storeName,
                    storeId,
                    locationId: selectedLocation.id
                })

                const payload = smsMarketingCampaign.payload

                await props.createSMSMarketingCampaign(storeId, locationId, payload, headers)
                props.onSuccess()

                setLoading(false)
                props.onClose()

            } catch (error) {
                setLoading(false)

            }
    }

    const handleSelectedLocation = (selectedLocation) => {
        setSelectedLocation(selectedLocation)
        setSelectedLocationPlaceholder(selectedLocation.name)
    }

    const handleShowImportCSVDialog = (showDialog) => {
        setShowImportCSVDialog(showDialog)
    }

    const handleImportedContacts = (importedContacts) => {
        setClients(importedContacts)
    }

    const handleAddClientsOnChange = (client) => {
        setClients([...clients, client])
    }


    return (
        <>
            <Dialog
                className="compose-message-dialog"
                style={{ borderRadius: "20em" }}
                open={props.active}
                onClose={() => props.onClose()}
                maxWidth="lg"
                fullWidth>
                <CreateMarketingCampaignDialogContainer>
                    <Container className="create-marketing-campaign-dialog--close-container" absolute flex fluid justifyEnd alignCenter padding="20px">
                        <CloseButtonContainer onClick={() => props.onClose()}>
                            <ReactSVG src={closeIcon} />
                        </CloseButtonContainer>
                    </Container>
                    <Container className="create-marketing-campaign-dialog--body-container" fluid flex>
                        <Container className="create-marketing-campaign-dialog--body-container__left-container" fluid maxWidth="50%" padding="40px">
                            <Label fontSize="24px" color="#333">Create Campaign</Label>
                            <Container fluid mt="25px">
                                <Container>
                                    <Label>Campaign Name</Label>
                                    <Container fluid mt="10px">
                                        <Input
                                        placeholder="Add name"
                                        fullWidth
                                        value={campaignName}
                                        onChange={handleCampaignName}/>
                                    </Container>
                                </Container>

                                <Container mt="30px">
                                    <Label>Location</Label>
                                    <Container>
                                        <SelectFluid
                                            placeholder={selectedLocationPlaceholder}
                                            showFullName
                                            options={props.locations}
                                            selectedOption={handleSelectedLocation}/>
                                    </Container>
                                </Container>

                                <Container mt="30px">
                                    <Label uppercase>Add clients</Label>
                                    <Container mt="20px">
                                        <Button
                                            padding="0"
                                            flex
                                            hasIcon
                                            iconPath={downloadIcon}
                                            customBorder="1px solid #d0d0d0"
                                            bgColor="white"
                                            bgColorOnHover="#f4f4f4"
                                            outline
                                            width="14em"
                                            height="40px"
                                            color="#333"
                                            borderRadius="20em"
                                            text="Import CSV"
                                            onClick={() => handleShowImportCSVDialog(true)}/>
                                    </Container>
                                    <Container mt="25px">
                                        <AddClient onAdd={handleAddClientsOnChange} />
                                    </Container>
                                    <Container mt="25px">
                                        <ClientsList clients={clients} onDelete={handleDeleteClient} />
                                    </Container>
                                </Container>
                            </Container>
                        </Container>
                        <Container
                            className="create-marketing-campaign-dialog--body-container__right-container"
                            fluid
                            maxWidth="50%"
                            backgroundColor="#eee"
                            padding="40px">
                            <Container
                                className="create-marketing-campaign-dialog--body-container__right-container--message-container"
                                mt="40px">
                                <Label semiBold>Message</Label>
                                <Container fluid mt="20px">
                                    <Textarea
                                        maxheight="30vh"
                                        value={message}
                                        minHeight="30vh"
                                        placeholder="Write a message"
                                        onChange={(e) => handleMessage(e.target.value)}>
                                    </Textarea>
                                </Container>
                            </Container>
                            <Container mt="60px" flex fluid alignCenter justifyEnd>
                                <Button
                                    disabled={!enableSubmitCampaign}
                                    loading={loading}
                                    primary
                                    padding="0"
                                    flex
                                    width="14em"
                                    height="40px"
                                    color="white"
                                    borderRadius="20em"
                                    text="Submit campaign"
                                    onClick={handleSendMessages}/>
                            </Container>
                        </Container>
                    </Container>
                </CreateMarketingCampaignDialogContainer>
            </Dialog>
            <ImportContactsDialog
                active={showImportCSVDialog}
                onChange={handleImportedContacts}
                onClose={() => handleShowImportCSVDialog(false)}
                onCancel={handleShowImportCSVDialog}/>
        </>
    )
}

const mapStateToProps = state => ({
    chatCredentials: state.webchat.chatCredentials,
    locations: state.locations.locations
})

const mapDispatchToProps = dispatch => ({
    updateChatGroupsMenu(updateChatGroups, chatGroups){
        dispatch(updateChatGroupsMenu(updateChatGroups, chatGroups))
    },

    setCurrentChat(currentChat){
        dispatch(setCurrentChat(currentChat))
    },

    async createSMSMarketingCampaign(storeId, locationId, payload, headers) {
        await dispatch(createSMSMarketingCampaign(storeId, locationId, payload, headers))
        return
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateMarketingCampaignDialog)
