// LIBRARIES
import axios from "axios"

// CONFIG
import {WEBCHAT_API_URL} from "../../../config"

// MUTATIONS
import { SET_MARKETING_CAMPAIGNS } from "./mutations"
import store from "../../index";


export const fetchMarketingCampaigns = (storeId) => async (dispatch) => {
    try {
         const response = await axios.get(`${WEBCHAT_API_URL}/stores/${storeId}/marketingCampaigns`)

        const marketingCampaigns = response.data

        dispatch({ type: SET_MARKETING_CAMPAIGNS, payload: marketingCampaigns })

        return marketingCampaigns

    } catch (error) {
        throw new Error(error)
    }
}


export const createSMSMarketingCampaign = (storeId, locationId, payload, headers) => async (dispatch) => {
    try {
        const endpoint = `${WEBCHAT_API_URL}/stores/${storeId}/locations/${locationId}/marketingCampaigns`
        const response = await axios.post(endpoint, payload, headers)

        const newMarketingCampaign = response.data

        const { marketingCampaigns } = store.getState().smsMarketing
        marketingCampaigns.push(newMarketingCampaign)

        dispatch({ type: SET_MARKETING_CAMPAIGNS, payload: marketingCampaigns })
        return newMarketingCampaign

    } catch (error) {
        throw new Error(error)
    }
}
