import React from "react";
import { MenuContainer, Label, DataAnalysisContainer, ManagementContainer, FaqContainer } from "./styled";
import MenuOptions from "./MenuOptions/MenuOptions";
import dashboardIcon from "../../../Assets/icons/dashboard.svg";
import analyticsIcon from "../../../Assets/icons/analytics.svg";
import reviewsIcon from "../../../Assets/icons/reviews.svg";
import reportsIcon from "../../../Assets/icons/reports.svg";
import faqIcon from '../../../Assets/icons/faq.svg';
import exchangeIcon from '../../../Assets/icons/exchange.svg';
import chatIcon from '../../../Assets/icons/chat.svg';
import settingsIcon from '../../../Assets/icons/settings.svg'
import twilioIcon from '../../../Assets/icons/twilio.svg'
import smsIcon from "../../../Assets/icons/mobile-sms.svg"

export default class Menu extends React.Component {

  state = {
    currentTab: "/dashboard",

  }

  componentDidMount(){
    const url = window.location;
    let path = url.pathname;
    this.setState({ currentTab: path })
  }


  handleMenuActive = (isMenuActive) => {
    this.props.isMenuActive(isMenuActive)
  }

  handleCurrentTab = (currentTab) => this.setState({ currentTab });

  render() {

    const widgetOnly = localStorage.getItem("widget_only");
    const chatOnly = localStorage.getItem("chat_only");
    const userRole = localStorage.getItem("role");
    let analyticOptions = []
    let managementOptions = []
    let privateActions = []
    let settingsOptions = []

    if (widgetOnly == "1" && chatOnly == "0"){
      analyticOptions = [];
      managementOptions = [
        { value: "Store Location", path: "/store-location", icon: analyticsIcon }
      ]
    } else if (chatOnly == "1" && widgetOnly == "0") {
      analyticOptions = [
        { value: "Reviews", path: "/reviews", icon: reviewsIcon },
      ]

      managementOptions = [
        { value: "Team", path: "/team", icon: dashboardIcon },
        { value: "Store Location", path: "/store-location", icon: analyticsIcon },
        { value: "Webchat", path: "/webchat", icon: chatIcon },
        // { value: "SMS Marketing", path: "/sms-marketing", icon: smsIcon }
      ]

      settingsOptions = [
        { value: "Webchat Settings", path: "/webchat/settings", icon: settingsIcon },
      ]
    } else if (chatOnly == "1" && widgetOnly == "1"){
      analyticOptions=[
        { value: "Reviews", path: "/reviews", icon: reviewsIcon },
      ]

      managementOptions = [
        { value: "Team", path: "/team", icon: dashboardIcon },
        { value: "Store Location", path: "/store-location", icon: analyticsIcon },
        { value: "Webchat", path: "/webchat", icon: chatIcon },
        // { value: "SMS Marketing", path: "/sms-marketing", icon: smsIcon }
      ]

      settingsOptions = [
        { value: "Webchat Settings", path: "/webchat/settings", icon: settingsIcon },
      ]
    } else {
      analyticOptions = [
        { value: "Dashboard", path: "/dashboard", icon: dashboardIcon },
        { value: "Location Analytics", path: "/locations-analytics", icon: analyticsIcon },
        { value: "Reviews", path: "/reviews", icon: reviewsIcon },
        { value: "Reports", path: "/reports", icon: reportsIcon }
      ]

      managementOptions = [
        { value: "Team", path: "/team", icon: dashboardIcon },
        { value: "Store Location", path: "/store-location", icon: analyticsIcon },
        { value: "Webchat", path: "/webchat", icon: chatIcon },
        // { value: "SMS Marketing", path: "/sms-marketing", icon: smsIcon }
      ]

      settingsOptions = [
        { value: "Webchat Settings", path: "/webchat/settings", icon: settingsIcon },
        { value: "FAQ", path: "/faq", icon: faqIcon }
      ]

      if (userRole === "sas-team"){
        privateActions = [
          { value: "Change Store", path: "/stores", icon: exchangeIcon },
          { value: "Twilio Number", path: "/admin/twilio-number", icon: twilioIcon },
          { value: "Webchat Analytics", path: "/admin/webchat-analytics", icon: analyticsIcon }
        ]
      } else {
        privateActions = []
      }
    }

    return (
      <MenuContainer>
        <DataAnalysisContainer>
          {(widgetOnly == "1" && chatOnly == "1") || chatOnly == "1" ? <Label>Data analysis</Label> : null}
          <MenuOptions
            isMenuActive={this.handleMenuActive}
            handleCurrentTab={this.handleCurrentTab}
            currentTab={this.state.currentTab}
            options={analyticOptions}
          />
        </DataAnalysisContainer>

        <ManagementContainer>
        <Label>Management</Label>
          <MenuOptions
            isMenuActive={this.handleMenuActive}
            handleCurrentTab={this.handleCurrentTab}
            currentTab={this.state.currentTab}
            options={managementOptions}
          />
        </ManagementContainer>

        {userRole === "sas-team" && (
        <ManagementContainer>
        <Label>SAS Team only</Label>
          <MenuOptions
            isMenuActive={this.handleMenuActive}
            handleCurrentTab={this.handleCurrentTab}
            currentTab={this.state.currentTab}
            options={privateActions}
          />
        </ManagementContainer>)}

        <FaqContainer>
          <hr />
          <MenuOptions
          handleCurrentTab={this.handleCurrentTab}
          currentTab={this.state.currentTab}
          isMenuActive={this.handleMenuActive}
          options={settingsOptions} />
        </FaqContainer>
      </MenuContainer>
    );
  }
}
